export enum PageType {
  Default = 'default',
  News = 'news',
  SuccessStory = 'success_story',
}

export interface Page extends ApiEntity {
  pageType: PageType
  title: string
  slug: string
  isPublished: boolean
  layout: PageLayout
  showTitleOnPage: boolean

  // News & SEO
  // TODO: get rid of the nullable types, this is done to fix typing issues in form fields
  summary?: string | null
  // TODO: we'd like this serialized as a ContentFile
  headerImageUrl?: string | null
  featured: boolean | null
  metaDescription?: string | null
  metaTitle?: string | null
  ogDescription?: string | null
  ogImage?: string | null
  ogTitle?: string | null
}

export async function fetchPage(id: id) {
  const {
    attributes: page,
    pending,
    error,
    refresh,
    relationships,
  } = await useApiFetch<Page, { contentBlocks: ContentBlock[] }>(
    `/api/v1/cms/pages/${id}?include=content_blocks`,
  )
  const contentBlocks = computed(() => relationships.value?.contentBlocks)
  return { page, contentBlocks, pending, error, refresh }
}

export async function fetchPageBySlug(slug: string) {
  const {
    attributes: page,
    pending,
    error,
    refresh,
    relationships,
  } = await useApiFetch<Page, { contentBlocks: ContentBlock[] }>(
    `/api/v1/cms/pages/slug/${slug}?include=content_blocks`,
    {},
    false, // Don't throw an error if the page doesn't exist
  )
  const contentBlocks = computed(() => relationships.value?.contentBlocks)
  return { page, contentBlocks, pending, error, refresh }
}

export async function fetchPages(
  query?: SearchParameters | Ref<SearchParameters>,
) {
  return useJsonApiFetch<Page[]>('/api/v1/cms/pages', { query })
}

export async function createPage(attributes: Partial<Page>) {
  await $create<Page>({
    resourceName: 'page',
    values: attributes,
  })
}

export async function updatePage(id: id, attributes: Partial<Page>) {
  await $update<Page>({
    id,
    resourceName: 'page',
    values: attributes,
  })
}

export async function deletePage(id: id) {
  await $api(`/api/v1/cms/pages/${id}`, {
    method: 'DELETE',
  })
}
